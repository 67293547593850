import "./src/styles/global.css"

export const shouldUpdateScroll = ({ routerProps }) => {
  const hasAnchor = !!routerProps.location.hash // (ie. /#features)

  return hasAnchor
}

export const onInitialClientRender = () => {
  //Remove Gatsby reference
  document.getElementById("___gatsby").removeAttribute("id")
  const ids = document.querySelectorAll('*[id^="gatsby"]')
  ids.forEach(el => el.removeAttribute("id"))
}
