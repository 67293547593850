// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-go-index-jsx": () => import("./../../../src/templates/Go/index.jsx" /* webpackChunkName: "component---src-templates-go-index-jsx" */),
  "component---src-templates-home-index-jsx": () => import("./../../../src/templates/Home/index.jsx" /* webpackChunkName: "component---src-templates-home-index-jsx" */),
  "component---src-templates-posts-index-jsx": () => import("./../../../src/templates/Posts/index.jsx" /* webpackChunkName: "component---src-templates-posts-index-jsx" */)
}

